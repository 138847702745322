import { Link } from "gatsby";
import React from "react";
import {
  Container,
  Button,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  Image
} from "react-bootstrap";
import logo from "../../images/logo.svg";

const Header = ({ handleModal }) => {
  return (
    <>
      <Container>
        <Navbar expand="lg" className="sf-navbar">
          <Navbar.Brand as={Link} to="/">
            <Image
              src={logo}
              className="d-inline-block align-top"
              alt="Softbinator Foundation"
            />
          </Navbar.Brand>
          <Button
            className="d-block d-lg-none donate"
            variant="primary"
            onClick={handleModal}
          >
            Donate
          </Button>
          <Navbar.Toggle aria-controls="top-navbar" />
          <Navbar.Collapse id="top-navbar">
            <Nav className="ml-auto">
              <NavItem href="/about">
                <Nav.Link as={Link} activeClassName="active" to="/about">
                  About us
                </Nav.Link>
              </NavItem>
              <NavItem>
                <NavDropdown title="What we do" id="what-we-do">
                  <NavDropdown.Item as={Link} to="/talks">
                    Talks by Softbinator
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/media">
                    Media
                  </NavDropdown.Item>
                </NavDropdown>
              </NavItem>
              <NavItem>
                <NavDropdown title="Get involved" id="get-involved">
                  <NavDropdown.Item as={Link} to="/companies">
                    Companies
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/individuals">
                    Individuals
                  </NavDropdown.Item>
                </NavDropdown>
              </NavItem>
              <NavItem>
                <Nav.Link as={Link} activeClassName="active" to="/contact">
                  Contact
                </Nav.Link>
              </NavItem>
              <NavItem className="d-none d-lg-block">
                <Button
                  className="donate"
                  variant="primary"
                  onClick={handleModal}
                >
                  Donate
                </Button>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  );
};

export default Header;
