import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Modal,
  Form
} from "react-bootstrap";
import { P, H2, Span } from "../Typography/Typography";
import logo from "../../images/logo.svg";

const Donate = ({ showModal, handleModal }) => {
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const [donateAmount, setDonateAmount] = useState();
  const [validated, setValidated] = useState(false);

  const handleDetalii = event => {
    setShowCustomAmount(false);
    setShowDetailsForm(true);
    setDonateAmount(event.target.value);
  };

  const handleDonateAmount = event => {
    setDonateAmount(event.target.value);
  };

  const handleCustomAmount = () => {
    setShowCustomAmount(true);
    setShowDetailsForm(true);
    setDonateAmount();
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const form = e.target;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      axios({
        method: "post",
        url: "/donatii/plateste.php",
        data: new FormData(form)
      })
        .then(r => {
          document.write(r.data);
        })
        .catch(r => {
          console.log(r);
        });
    }

    setValidated(true);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleModal} backdrop="static">
        <Modal.Header closeButton />
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={7}>
                <H2>Make a donation</H2>
                <P>
                  Bring your contribution and help us to continue our work,
                  become sustainable and grow our community.
                </P>
              </Col>
              <Col lg={5}>
                <Image src={logo} className="img-fluid" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form
                  className="doneaza"
                  onSubmit={handleOnSubmit}
                  noValidate
                  validated={validated}
                  name="doneaza"
                >
                  <div className="select-amount mb-3">
                    <Form.Group controlId="select-amount">
                      <Form.Check
                        inline
                        label={`50 lei`}
                        type="radio"
                        value="50"
                        id="amount-0"
                        name="select-amount"
                        onChange={handleDetalii}
                        checked={donateAmount === "50"}
                      />

                      <Form.Check
                        inline
                        label={`100 lei`}
                        type="radio"
                        value="100"
                        id="amount-1"
                        name="select-amount"
                        onChange={handleDetalii}
                        checked={donateAmount === "100"}
                      />

                      <Form.Check
                        inline
                        label={`200 lei`}
                        type="radio"
                        value="200"
                        id="amount-2"
                        name="select-amount"
                        onChange={handleDetalii}
                        checked={donateAmount === "200"}
                      />
                    </Form.Group>

                    <P>
                      <Span onClick={handleCustomAmount}>
                        Donate any amount
                      </Span>
                    </P>
                  </div>

                  {showDetailsForm && (
                    <div className="details">
                      <Form.Group controlId="amount">
                        <Form.Control
                          name="amount"
                          type="hidden"
                          defaultValue={donateAmount}
                        />
                      </Form.Group>

                      {showCustomAmount && (
                        <Form.Group controlId="custom-amount">
                          <Form.Control
                            name="custom-amount"
                            type="number"
                            placeholder="Enter amount"
                            onChange={handleDonateAmount}
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Enter the amount you want to donate.
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}

                      <Form.Group controlId="recurent">
                        <Form.Label>Recurrent</Form.Label>
                        <Form.Control
                          as="select"
                          defaultValue="yes"
                          name="recurent"
                        >
                          <option value="yes" name="yes">
                            Yes
                          </option>
                          <option value="no" name="no">
                            No
                          </option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="fname">
                        <Form.Control
                          name="fname"
                          type="text"
                          placeholder="First name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          First name is required.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="lname">
                        <Form.Control
                          name="lname"
                          type="text"
                          placeholder="Last name"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Last name is required.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="email">
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="E-mail"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          E-mail address is invalid.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="phone">
                        <Form.Control
                          name="phone"
                          type="number"
                          placeholder="Phone"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Phone number is required.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button variant="primary" type="submit">
                        Donate
                      </Button>
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Donate;
