import React from "react";
import PropTypes from "prop-types";

export const P = ({ children, ...rest }) => {
  return <p {...rest}>{children}</p>;
};

export const Span = ({ children, ...rest }) => {
  return <span {...rest}>{children}</span>;
};

export const H1 = ({ children, ...rest }) => {
  return <h1 {...rest}>{children}</h1>;
};

export const H2 = ({ children, ...rest }) => {
  return <h2 {...rest}>{children}</h2>;
};

export const H3 = ({ children, ...rest }) => {
  return <h3 {...rest}>{children}</h3>;
};

export const H4 = ({ children, ...rest }) => {
  return <h4 {...rest}>{children}</h4>;
};

export const H5 = ({ children, ...rest }) => {
  return <h5 {...rest}>{children}</h5>;
};

export const H6 = ({ children, ...rest }) => {
  return <h6 {...rest}>{children}</h6>;
};

P.propTypes = {
  children: PropTypes.node.isRequired
};

Span.propTypes = {
  children: PropTypes.node.isRequired
};

H1.propTypes = {
  children: PropTypes.node.isRequired
};

H2.propTypes = {
  children: PropTypes.node.isRequired
};

H3.propTypes = {
  children: PropTypes.node.isRequired
};

H4.propTypes = {
  children: PropTypes.node.isRequired
};

H5.propTypes = {
  children: PropTypes.node.isRequired
};

H6.propTypes = {
  children: PropTypes.node.isRequired
};
