import React, { useState } from "react";
import PropTypes from "prop-types";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Donate from "../Donate/Donate";
import "../../styles/styles.scss";

const Layout = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => setShowModal(!showModal);

  return (
    <>
      <Header handleModal={handleModal} />
      <main>{children}</main>
      <Footer handleModal={handleModal} />
      <Donate showModal={showModal} handleModal={handleModal} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
